import * as React from "react";
import { GoogleAnalyticsActions } from "../../../hooks/useGoogleAnalytics";
import FgvContext from "./FgvContext";
import './styles.css';

type Props = {
  fgvUser: any,
  onRegisterClick?: React.MouseEventHandler<HTMLAnchorElement>  | undefined ,
  currentDashboard: string
}

const FgvCounter: React.FC<Props> = ({ fgvUser, onRegisterClick }: Props) => {
  const { visits: counterVisitor } = React.useContext(FgvContext);

  const message = [
    <p>Você está vendo a versão gratuita. Faça seu <span onClick={() =>  GoogleAnalyticsActions.event('iniciou_cadastro', '', 'cadastro_barra_contagem')}><a href="#" onClick={onRegisterClick} id-event="cadastro_barra_contagem">cadastro</a></span> para acessar preços também por categoria.</p>,
    <p>Você está vendo a versão plus. Você já fez {counterVisitor && counterVisitor} de 10 acessos.</p>,
    <p>Acabaram suas visitas à versão plus. <a target="__blank" href="https://dashboard.ehorus.com.br/signup/premium">Assine</a> para ter acesso.</p>
  ];


  return (<>
    <div className="hd-fgv__counter">
      { !fgvUser && message[0] }
      { fgvUser && counterVisitor <= 10 && message[1] }
      { fgvUser && counterVisitor > 10 && message[2] }
    </div>
  </>);
}

export default FgvCounter


import React, {
  ComponentProps,
  ElementRef,
  forwardRef,
  ForwardRefRenderFunction,
  FunctionComponent,
  ReactNode,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Link, useHistory } from "react-router-dom";
import useTitle from "../../../hooks/useTitle";
import { getErrorMessage } from "../../utils/Util";
import FgvCounter from "./FgvCounter";
import FgvMenu from "./FgvMenu";
import Modal from "../modals/Modal";
import useFgv from "./useFgv";
import "./FgvPage.css";
import { GoogleAnalyticsActions } from "../../../hooks/useGoogleAnalytics";
import { maskPhone } from "../../utils/Mask";

function goToPlans(e: React.MouseEvent) {
  e.preventDefault();
  const element = document.querySelector(".hd-fgv__pricing");
  if (!element || !element.scrollIntoView) return;
  element.scrollIntoView({
    behavior: "smooth",
  });
}

const FaqItem: FunctionComponent<{ title: string; ga: string }> = function (
  props
) {
  const [active, setActive] = useState(false);
  return (
    <div
      className={`hd-fgv__faq-item ${active ? "hd-fgv__faq-item--open" : ""}`}
    >
      <div
        className="hd-fgv__faq-title"
        onClick={(e) => {
          setActive(!active);
          GoogleAnalyticsActions.event(
            props.ga,
            active ? "close" : "open",
            "Faq"
          );
        }}
      >
        {props.title}
        <div
          className="hd-fgv__faq-item__expand"
          onClick={(e) => setActive(!active)}
        />
      </div>
      <div className="hd-fgv__faq-description-wrapper">
        <div className="hd-fgv__faq-description">{props.children}</div>
      </div>
    </div>
  );
};

const Slider: FunctionComponent = function (props) {
  const slides = [
    {
      href: "/",
      backgroundImage: "url(/static/noticia1.png)",
    },
    {
      href: "/",
      backgroundImage: "url(/static/noticia2.png)",
    },
    {
      href: "/",
      backgroundImage: "url(/static/noticia3.png)",
    },
    {
      href: "/",
      backgroundImage: "url(/static/noticia4.png)",
    },
    {
      href: "/",
      backgroundImage: "url(/static/noticia5.png)",
    },
  ];

  const [page, setPage] = useState<number>(0);
  const items: ReactNode[] = [];

  const canGoForward = page + 2 < slides.length;
  const canGoBackward = page > 0;

  for (let i = page; i < page + 2; i++) {
    const { backgroundImage } = slides[i];
    items.push(
      <div className="hd-fgv__on-media__col" key={i}>
        <div className="hd-fgv__on-media__item" style={{ backgroundImage }} />
      </div>
    );
  }

  function onGoForward() {
    if (canGoForward) setPage(page + 1);
  }

  function onGoBackward() {
    if (canGoBackward) setPage(page - 1);
  }

  return (
    <div className="hd-fgv__on-media">
      <div className="hd-fgv__content">
        <h2 className="hd-fgv__title">
          Você pode já ter ouvido falar sobre a HORUS na mídia
        </h2>
        <div className="hd-fgv__on-media__row">
          <div
            onClick={onGoBackward}
            className={`horus__slider__control horus__slider__control__left ${canGoBackward ? "horus__slider__control--active" : ""
              }`}
          >
            <div></div>
            <div></div>
          </div>
          {items}
          <div
            onClick={onGoForward}
            className={`horus__slider__control horus__slider__control__right ${canGoForward ? "horus__slider__control--active" : ""
              }`}
          >
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
};

const AvailableCategoriesModal: FunctionComponent<
  ComponentProps<typeof Modal>
> = function (props) {
  return (
    <Modal {...props}>
      <div className="hd-fgv__categories-list">
        <h2 className="hd-fgv__title">Categorias Disponíveis</h2>
        <ul>
          <li>Achocolatado/Modificador</li>
          <li>Açúcar</li>
          <li>Água Mineral</li>
          <li>Água Sanitária</li>
          <li>Amaciantes para Roupa</li>
          <li>Arroz</li>
          <li>Azeite</li>
          <li>Batata Congelada</li>
          <li>Biscoitos</li>
          <li>Bovino</li>
          <li>Café em Pó e em Grãos</li>
          <li>Cerveja</li>
          <li>Chocolate</li>
          <li>Creme de Leite</li>
          <li>Creme Dental</li>
          <li>Desodorante</li>
          <li>Detergente Líquido</li>
          <li>Enlatados e Conservas</li>
          <li>Farinha de Mandioca</li>
          <li>Farinha de Trigo</li>
          <li>Fubá e Farinhas de Milho</li>
          <li>Feijão</li>
          <li>Frango</li>
          <li>Frutas (Banana)</li>
          <li>Frutas (Laranja)</li>
          <li>Frutas (Maçã)</li>
          <li>Hambúrguer</li>
          <li>Iogurte</li>
          <li>Legumes (Batata Inglesa)</li>

          <li>Legumes (Cebola)</li>
          <li>Legumes (Cenoura)</li>
          <li>Leite Condensado</li>
          <li>Leite em Pó</li>
          <li>Leite UHT</li>
          <li>Linguiça</li>
          <li>Manteiga</li>
          <li>Margarina</li>
          <li>Massas Alimentícias</li>
          <li>Massas Instantâneas</li>
          <li>Molho de Tomate</li>
          <li>Óleo</li>
          <li>Ovos</li>
          <li>Pão</li>
          <li>Papel Higiênico</li>
          <li>Queijos</li>
          <li>Refrigerante</li>
          <li>Requeijão</li>
          <li>Sabão para Roupa</li>
          <li>Sabonete</li>
          <li>Shampoo</li>
          <li>Snacks e Salgadinhos</li>
          <li>Suco Pronto</li>
          <li>Suíno</li>
          <li>Verduras (Alface)</li>
          <li>Verduras (Couve)</li>
          <li>Verduras (Repolho)</li>
          <li>Vinho</li>
        </ul>
      </div>
    </Modal>
  );
};

const RegisterModalWithoutRef: ForwardRefRenderFunction<
  {
    setEmail: (email: string) => void;
    focusEmail: () => void;
    focusFirstName: () => void;
  },
  ComponentProps<typeof Modal>
> = function (props, ref) {
  const { register } = useFgv();
  const { open } = props;
  const emailRef = useRef<HTMLInputElement>(null);
  const firstNameRef = useRef<HTMLInputElement>(null);
  const fullNameRef = useRef<HTMLInputElement>(null);
  const phoneNumberRef = useRef<HTMLInputElement>(null);

  const [state, setState] = useState({
    user: {
      email: "",
      name: "",
      phoneNumber: "",
    },
    error: undefined as undefined | string,
    loading: false,
  });

  useEffect(
    function () {
      if (!open)
        setState((prev) => ({
          ...prev,
          error: undefined,
          loading: false,
          user: {
            email: "",
            name: "",
            phoneNumber: "",
          },
        }));
    },
    [setState, open]
  );

  useImperativeHandle(ref, () => ({
    setEmail(email) {
      setState((prev) => ({ ...prev, user: { ...prev.user, email } }));
    },
    focusEmail() {
      setTimeout(function () {
        emailRef.current?.focus?.();
      }, 500);
    },
    focusFirstName() {
      setTimeout(function () {
        firstNameRef.current?.focus?.();
      }, 500);
    },
  }));

  const onFormSubmit = async function (e: React.FormEvent) {
    e.preventDefault();

    try {
      setState((prev) => ({ ...prev, loading: true, error: undefined }));
      await register({ ...state.user });
      props.onClose?.();
      window.scrollTo(0, 0);
      window.location.href = "/plus";
    } catch (e) {
      setState((prev) => ({
        ...prev,
        loading: false,
        error: getErrorMessage(e),
      }));
    }
  };

  const disabled =
    !state.user.email ||
    !state.user.name ||
    !state.user.phoneNumber ||
    state.loading;

  return (
    <Modal {...props}>
      <p className="hd-fgv__register__text">
        Faça seu cadastro e veja informações
        <br />
        mais completas de categorais
        <br />
        da cesta do consumidor
      </p>

      <form className="hd-fgv__register__form" onSubmit={onFormSubmit}>
        <input
          ref={emailRef}
          type="text"
          placeholder="Email Corporativo"
          value={state.user.email}
          onChange={(e) =>
            setState((prev) => ({
              ...prev,
              user: { ...prev.user, email: e.target.value },
            }))
          }
          className="hd-fgv__register__input"
        />

        <input
          ref={fullNameRef}
          type="text"
          placeholder="Nome Completo"
          value={state.user.name}
          onChange={(e) =>
            setState((prev) => ({
              ...state,
              user: { ...prev.user, name: e.target.value },
            }))
          }
          className="hd-fgv__register__input"
        />
        <input
          ref={phoneNumberRef}
          type="text"
          placeholder="Telefone"
          value={state.user.phoneNumber}
          onChange={(e) => {
            let phone: string = maskPhone(e.target.value) || "";

            setState((prev) => ({
              ...state,
              user: { ...prev.user, phoneNumber: phone },
            }));
          }}
          className="hd-fgv__register__input"
        />
        <button
          className="hd-fgv__register__btn"
          onClick={() => GoogleAnalyticsActions.event("finalizou_cadastro", "")}
          disabled={disabled}
        >
          {state.loading ? "Carregando..." : "Entrar"}
        </button>

        {!state.error ? null : (
          <div
            style={{ marginTop: ".5em", fontWeight: "bolder", color: "red" }}
          >
            {state.error}
          </div>
        )}
      </form>
    </Modal>
  );
};
const RegisterModal = forwardRef(RegisterModalWithoutRef);

const FgvPage: FunctionComponent<{ fullPage?: boolean }> = function (props) {
  useTitle("HORUS & FGV IBRE");

  const history = useHistory();
  const { fgvUser } = useFgv();
  const { children, fullPage = false } = props;
  const [registerModalOpen, setRegisterModalOpen] = useState(false);
  const [availableCategoriesModalOpen, setAvailableCategoriesModalOpen] =
    useState(false);

  const registerFormRef = useRef<ElementRef<typeof RegisterModal>>(null);
  const emailInputRef = useRef<HTMLInputElement>(null);

  const tagging = new GoogleAnalyticsActions("G-SX5Z63SCPY");

  const onSignPlusClick = (e: any) => {
    e.preventDefault();
    if (e.target?.getAttribute("id-event")) {
      tagging.eventName(e.target?.getAttribute("id-event"));
    }
    const currentRegisterFormRef = registerFormRef.current;
    const currentEmailInputRef = emailInputRef.current;
    if (currentEmailInputRef && currentRegisterFormRef) {
      currentRegisterFormRef.setEmail(currentEmailInputRef.value);
      currentEmailInputRef.value = "";
    }
    currentRegisterFormRef?.focusFirstName?.();
    setRegisterModalOpen(true);
  };

  const onRegisterClick = (e: any) => {
    e.preventDefault();
    if (e.target?.getAttribute("id-event")) {
      tagging.eventName(e.target?.getAttribute("id-event"));
    }

    if (fgvUser) {
      history.push("/plus");
      window.scrollTo(0, 0);
      return;
    }
    registerFormRef.current?.focusEmail?.();
    setRegisterModalOpen(true);
  };

  const onShowCategoriesClick = (e: React.MouseEvent) => {
    e.preventDefault();
    GoogleAnalyticsActions.event("botao_cat_disponiveis", "cestaconsumo", "ㅤ");
    setAvailableCategoriesModalOpen(true);
  };

  const currentDashboard = (function () {
    switch (window.location.pathname) {
      case "/plus":
        return "PLUS";
      default:
        return "BASIC";
    }
  })();

  React.useEffect(() => {
    if (window.location.hash === "#cadastro") {
      registerFormRef.current?.focusEmail?.();
      window.location.hash = "";
      setRegisterModalOpen(true);
    }
  });

  return (
    <div className="hd-fgv">
      <RegisterModal
        ref={registerFormRef}
        open={registerModalOpen}
        onClose={() => setRegisterModalOpen(false)}
      />
      <AvailableCategoriesModal
        open={availableCategoriesModalOpen}
        onClose={() => setAvailableCategoriesModalOpen(false)}
      />

      <FgvMenu
        goToPlans={goToPlans}
        onRegisterClick={onRegisterClick}
        fgvUser={fgvUser}
      />

      <FgvCounter
        onRegisterClick={onRegisterClick}
        currentDashboard={currentDashboard}
        fgvUser={fgvUser}
      />

      {!fullPage ? null : (
        <section className="hd-fgv__hero">
          <div className="content">
            <div>
              <h2>Acompanhe os valores de cestas de consumo do brasileiro</h2>
              <p>Acesse <b>gratuitamente</b> e conheça
                também os preços e variações
                dos principais produtos que o
                brasileiro coloca em seu
                carrinho quando vai ao
                supermercado.</p>

              <ul>
                <li><button onClick={(e) => {
                  e.preventDefault();
                  GoogleAnalyticsActions.event("botao_hero_cesta_consumo", "", "ㅤ");
                  window.location.href = "./#tool";
                }}>Cestas de Consumo</button>
                  <span>Acesse gratuitamente</span>
                </li>


                <li><button onClick={(e) => {
                  e.preventDefault();
                  GoogleAnalyticsActions.event("botao_hero_categoria_produto", "", "ㅤ");
                  !fgvUser ? window.location.href = "./#cadastro" : window.location.href = "./plus#tool"
                }}>Categorias de Produto</button>
                  <span>Cadastre-se e acesse gratuitamente</span>
                </li>




                {

                  !fgvUser ?
                    <li><button onClick={(e) => {
                      e.preventDefault();
                      GoogleAnalyticsActions.event("botao_hero_marca_fabricante", "", "ㅤ");
                      window.location.href = "./#plataforma-horus";
                    }}>Fabricantes e Marcas</button>
                      <span>Assine o plano Premium</span>
                    </li>
                    : <li><button onClick={(e) => {
                      e.preventDefault();
                      GoogleAnalyticsActions.event("botao_hero_marca_fabricante", "", "ㅤ");
                      window.location.href = "./#plataforma-horus"
                    }}>Fabricantes e Marcas</button>
                      <span>Assine o plano Premium</span>
                    </li>

                }

              </ul>

            </div>

            <div>
              <img src="/static/cesta.png" alt="" />
            </div>

          </div>
        </section>
      )}




      <div></div>
      <span id="tool" ></span>
      {!fullPage ? null : (
        <div className="hd-fgv__top-part">
          <div className="hd-fgv__content">
            {!fgvUser ? (
              <>
                {/* <>
                <form
                  className="hd-fgv__top-part__form"
                  onSubmit={onSignPlusClick}
                >
                  <input placeholder="Seu e-mail" ref={emailInputRef}></input>
                  <button><span>Ver dados por categoria</span><span>Enviar</span></button>
                </form>
                <p className="hd-fgv__top-part__obs">
                  É grátis. Não precisa de cartão de crédito.
                </p>
              </> */}
              </>
            ) : (
              <div className="hd-fgv__plus__top-part">
                <button
                  className="hd-fgv__plus__top-part__button"
                  onClick={() => {
                    GoogleAnalyticsActions.event(
                      "carrinho_compras",
                      "",
                      "marca_fabricante_acima_dash"
                    );
                    window.open(
                      "https://dashboard.ehorus.com.br/signup/premium",
                      "open"
                    );
                  }}
                >
                  Comprar dados por fabricante e marca
                </button>

                <div className="hd-fgv__plus__top-part__separator">- ou -</div>
                <div className="hd-fgv__plus__top-part__button-wrapper">
                  {currentDashboard === "BASIC" ? (
                    <button
                      onClick={() => {
                        window.location.href = "/plus#tool"
                      }}
                    >
                      Ver dados por categoria
                    </button>
                  ) : (
                    <button
                      onClick={() => {
                        GoogleAnalyticsActions.event(
                          "retorno_cesta_consumo",
                          "",
                          "ㅤ"
                        );
                        window.location.href = "/#tool"
                      }}
                    >
                      Cestas de Consumo
                    </button>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      )}


      {!fullPage ? null : (
        <div className="hd-fgv__top-part">
          <div className="hd-fgv__content">

          </div>
        </div>
      )}


      {children}
      <span id="sobre"></span>
      <div className="hd-fgv__extra "  >
        {!fullPage ? null : (
          <>
            <div className="hd-fgv__section-1">
              <div className="hd-fgv__section-1__decoration-1" />
              <div className="hd-fgv__section-1__decoration-2" />
              <div className="hd-fgv__content">
                <div style={{ display: "table" }}></div>
                <h2 className="hd-fgv__title">HORUS &amp; FGV IBRE</h2>
                <div className="hd-fgv__section-1__text">
                  A Horus e Instituto Brasileiro de Economia da FGV se uniram
                  para levar conhecimento sobre o comportamento de preço de
                  itens básicos para todos os brasileiros. Ao aliar a tecnologia
                  da HORUS na análise em tempo real de grandes quantidades de
                  informações do varejo aos mais de 70 anos de experiência do
                  FGV IBRE na produção de indicadores econômicos, conseguimos
                  trazer uma nova visão dos produtos mais relevantes para o
                  bolso do consumidor.
                </div>
                <div className="hd-fgv__section-1__row">
                  <div className="hd-fgv__section-1__col">
                    <div className="hd-fgv__section-1__item">
                      <img
                        className="hd-fgv__section-1__item__img"
                        src="/static/col1-icon.png"
                      />
                      <p className="hd-fgv__section-1__item__text">
                        Coleta de{" "}
                        <strong>
                          + de 35
                          <br />
                          milhões de notas fiscais
                        </strong>
                        <br />
                        todos os meses
                      </p>
                    </div>
                  </div>
                  <div className="hd-fgv__section-1__col">
                    <div className="hd-fgv__section-1__item">
                      <img
                        className="hd-fgv__section-1__item__img"
                        src="/static/col2-icon.png"
                      />
                      <p className="hd-fgv__section-1__item__text">
                        Comportamento de preços de
                        <br />
                        <strong>+ de 50 categorias</strong>
                      </p>
                    </div>
                    <div className="hd-fgv__section-1__link-1-wrapper">
                      <a
                        href="#"
                        className="hd-fgv__link"
                        onClick={onShowCategoriesClick}
                      >
                        Ver categorias disponíveis
                      </a>
                    </div>
                  </div>
                  <div className="hd-fgv__section-1__col">
                    <div className="hd-fgv__section-1__item">
                      <img
                        className="hd-fgv__section-1__item__img"
                        src="/static/col3-icon.png"
                      />
                      <p className="hd-fgv__section-1__item__text">
                        Divulgação dos preços
                        <br />
                        da <strong>cesta de compras</strong> do
                        <br />
                        consumidor brasileiro
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="hd-fgv__section-2" id="produtos">
              <div className="hd-fgv__content">
                <div className="hd-fgv__section-2__item" >
                  <div className="hd-fgv__section-2__text-part">
                    <div className="hd-fgv__section-2__sub-title">
                      Acompanhe os valores <br />
                      de cestas de consumo
                    </div>
                    <div className="hd-fgv__section-2__text">
                      Através de dados de dezenas de milhões de notas fiscais, acompanhe gratuitamente os valores médios gastos pelos consumidores em cestas de consumo básica e ampliada mês a mês em 8 capitais.
                    </div>
                    <div className="hd-fgv__section-2__text-wrapper">
                      <a href="/#tool" onClick={(e) => {
                        e.preventDefault();
                        GoogleAnalyticsActions.event("botao_produto_cesta_consumo", "", "ㅤ");
                        window.location.href = "./#tool";
                      }} className="btn-extra-section">Ver Cesta Consumo</a>
                    </div>
                  </div>
                  <div className="hd-fgv__section-2__spacer"></div>
                  <div
                    className="hd-fgv__section-2__image-part hd-fgv__section-2__image-part-1"
                    style={{ backgroundImage: "url(/static/img_mktshare.png)" }}
                  ></div>
                </div>
                <div className="hd-fgv__section-2__item hd-fgv__section-2__item--inverted">
                  <div
                    className="hd-fgv__section-2__image-part hd-fgv__section-2__image-part-2"
                    style={{
                      backgroundImage: "url(/static/img_precificacao.png)",
                    }}
                  ></div>
                  <div className="hd-fgv__section-2__spacer"></div>
                  <div className="hd-fgv__section-2__text-part hd-fgv__section-2__text-part-2">
                    <div className="hd-fgv__section-2__sub-title">
                      Acesse gratuitamente o preço por categoria
                      de produtos
                    </div>
                    <div className="hd-fgv__section-2__text">
                      Na versão Plus, entenda o valor médio pago pelo consumidor em cada uma das categorias de produto que compõem as cestas.
                    </div>

                    <div className="hd-fgv__section-2__text-wrapper">
                      <a href="/#cadastro" onClick={(e) => {
                        e.preventDefault();
                        GoogleAnalyticsActions.event("botao_produto_plano_plus", "", "ㅤ");
                        fgvUser ? window.location.href = "/plus/#tool" : window.location.href = "./#cadastro"
                      }} className="btn-extra-section">Acessar Versão Plus</a>
                    </div>


                  </div>
                </div>
                <span id="plataforma-horus"></span>
                <div className="hd-fgv__section-2__item hd-fgv__section-2__item-3" >
                  <div className="hd-fgv__section-2__text-part">
                    <div className="hd-fgv__section-2__sub-title">
                      Monitore em detalhes a dinâmica de preços dentro das categorias de produtos de interesse
                    </div>
                    <div className="hd-fgv__section-2__text">
                      Assinando o Premium,
                      navegue pela <b>Plataforma
                        Horus</b> de forma intuitiva e
                      por todos os filtros
                      disponíveis. Você
                      vai acompanhar as
                      movimentações de preço e
                      analisar as diferenças entre
                      fabricantes, marcas e até
                      tamanhos de embalagem.
                    </div>

                    <div className="hd-fgv__section-2__text-wrapper">
                      <a href="/#cadastro" onClick={(e) => {
                        e.preventDefault();
                        GoogleAnalyticsActions.event("botao_produto_plano_premium", "", "ㅤ");
                        window.open("https://dashboard.ehorus.com.br/signup/premium", '_blank');
                      }} className="btn-extra-section">Assinar Plano Premium</a>
                    </div>
                  </div>
                  <div className="hd-fgv__section-2__spacer"></div>
                  <div
                    className="hd-fgv__section-2__image-part hd-fgv__section-2__image-part-3"
                    style={{ backgroundImage: "url(/static/img_marcas.png)" }}
                  ></div>
                </div>
              </div>
            </div>
          </>
        )}
        <span id="planos"></span>
        <div className="hd-fgv__pricing" >
          <div className="hd-fgv__content">
            <h2 className="hd-fgv__title">Conheça nossos planos</h2>
            <div className="hd-fgv__pricing__table-wrapper">
              <table className="hd-fgv__pricing__table">
                <thead>
                  <tr>
                    <th></th>
                    <th>
                      Cesta do
                      <br />
                      consumidor
                    </th>
                    <th>Plus</th>
                    <th>Premium</th>
                    <th>
                      Outras soluções
                      <br />
                      Horus
                    </th>
                  </tr>
                  <tr>
                    <th></th>
                    <th>Grátis</th>
                    <th>Grátis</th>
                    <th>
                      <div className="__small">a partir de</div>
                      R$ 1.757
                    </th>
                    <th></th>
                  </tr>
                  <tr>
                    <th></th>
                    <th>atualização mensal</th>
                    <th>atualização mensal</th>
                    <th>atualização mensal</th>
                    <th></th>
                  </tr>
                  <tr>
                    <th>
                      Veja preços e<br />
                      variação de:
                    </th>
                    <th></th>
                    <th>
                      {fgvUser ? null : (
                        <span
                          onClick={() =>
                            GoogleAnalyticsActions.event(
                              "iniciou_cadastro",
                              "",
                              "planos_plus"
                            )
                          }
                        >
                          <a
                            href="#"
                            id-event="plano_plus"
                            onClick={onRegisterClick}
                          >
                            Acessar
                          </a>
                        </span>
                      )}
                    </th>
                    <th>
                      <button
                        onClick={() => {
                          GoogleAnalyticsActions.event(
                            "assinar_planos",
                            "cestaconsumo",
                            "ㅤ"
                          );
                          window.open('https://dashboard.ehorus.com.br/signup/premium', '_blank')
                        }}
                      >
                        Assinar
                      </button>
                    </th>
                    <th>
                      <button
                        onClick={() => {
                          GoogleAnalyticsActions.event(
                            "saiba_mais_planos",
                            "cestaconsumo",
                            "ㅤ"
                          );
                          window.open('https://www.ehorus.com.br/', '_blank')
                        }}
                      >
                        Saiba Mais
                      </button>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Cesta de consumo básica</td>
                    <td>
                      <div className="hd-fgv__pricing__feature hd-fgv__pricing__feature--active" />
                    </td>
                    <td>
                      <div className="hd-fgv__pricing__feature hd-fgv__pricing__feature--active" />
                    </td>
                    <td>
                      <div className="hd-fgv__pricing__feature hd-fgv__pricing__feature--active" />
                    </td>
                    <td>
                      <div className="hd-fgv__pricing__feature hd-fgv__pricing__feature--active" />
                    </td>
                  </tr>
                  <tr>
                    <td>Cesta de consumo ampliada</td>
                    <td>
                      <div className="hd-fgv__pricing__feature hd-fgv__pricing__feature--active" />
                    </td>
                    <td>
                      <div className="hd-fgv__pricing__feature hd-fgv__pricing__feature--active" />
                    </td>
                    <td>
                      <div className="hd-fgv__pricing__feature hd-fgv__pricing__feature--active" />
                    </td>
                    <td>
                      <div className="hd-fgv__pricing__feature hd-fgv__pricing__feature--active" />
                    </td>
                  </tr>
                  <tr>
                    <td>Categoria de produtos</td>
                    <td>
                      <div className="hd-fgv__pricing__feature" />
                    </td>
                    <td>
                      <div className="hd-fgv__pricing__feature hd-fgv__pricing__feature--active" />
                    </td>
                    <td>
                      <div className="hd-fgv__pricing__feature hd-fgv__pricing__feature--active" />
                    </td>
                    <td>
                      <div className="hd-fgv__pricing__feature hd-fgv__pricing__feature--active" />
                    </td>
                  </tr>
                  <tr>
                    <td>Segmento de categoria</td>
                    <td>
                      <div className="hd-fgv__pricing__feature" />
                    </td>
                    <td>
                      <div className="hd-fgv__pricing__feature" />
                    </td>
                    <td>
                      <div className="hd-fgv__pricing__feature hd-fgv__pricing__feature--active" />
                    </td>
                    <td>
                      <div className="hd-fgv__pricing__feature hd-fgv__pricing__feature--active" />
                    </td>
                  </tr>
                  <tr>
                    <td>Fabricante</td>
                    <td>
                      <div className="hd-fgv__pricing__feature" />
                    </td>
                    <td>
                      <div className="hd-fgv__pricing__feature" />
                    </td>
                    <td>
                      <div className="hd-fgv__pricing__feature hd-fgv__pricing__feature--active" />
                    </td>
                    <td>
                      <div className="hd-fgv__pricing__feature hd-fgv__pricing__feature--active" />
                    </td>
                  </tr>
                  <tr>
                    <td>Marca</td>
                    <td>
                      <div className="hd-fgv__pricing__feature" />
                    </td>
                    <td>
                      <div className="hd-fgv__pricing__feature" />
                    </td>
                    <td>
                      <div className="hd-fgv__pricing__feature hd-fgv__pricing__feature--active" />
                    </td>
                    <td>
                      <div className="hd-fgv__pricing__feature hd-fgv__pricing__feature--active" />
                    </td>
                  </tr>
                  <tr>
                    <td>Tamanho da embalagem</td>
                    <td>
                      <div className="hd-fgv__pricing__feature" />
                    </td>
                    <td>
                      <div className="hd-fgv__pricing__feature" />
                    </td>
                    <td>
                      <div className="hd-fgv__pricing__feature hd-fgv__pricing__feature--active" />
                    </td>
                    <td>
                      <div className="hd-fgv__pricing__feature hd-fgv__pricing__feature--active" />
                    </td>
                  </tr>
                  <tr>
                    <td>Canal varejista</td>
                    <td>
                      <div className="hd-fgv__pricing__feature" />
                    </td>
                    <td>
                      <div className="hd-fgv__pricing__feature" />
                    </td>
                    <td>
                      <div className="hd-fgv__pricing__feature" />
                    </td>
                    <td>
                      <div className="hd-fgv__pricing__feature hd-fgv__pricing__feature--active" />
                    </td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: "bolder" }}>
                      Mais métricas de performance
                    </td>
                    <td>
                      <div className="hd-fgv__pricing__feature" />
                    </td>
                    <td>
                      <div className="hd-fgv__pricing__feature" />
                    </td>
                    <td>
                      <div className="hd-fgv__pricing__feature" />
                    </td>
                    <td>
                      <div className="hd-fgv__pricing__feature hd-fgv__pricing__feature--active" />
                    </td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: "bolder" }}>
                      Mais dimensões geográficas
                    </td>
                    <td>
                      <div className="hd-fgv__pricing__feature" />
                    </td>
                    <td>
                      <div className="hd-fgv__pricing__feature" />
                    </td>
                    <td>
                      <div className="hd-fgv__pricing__feature" />
                    </td>
                    <td>
                      <div className="hd-fgv__pricing__feature hd-fgv__pricing__feature--active" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {
          <>
            <div className="hd-fgv__important">
              <div className="hd-fgv__content ">
                <span>Importante!</span> Nossa metodologia é baseada em uma amostra aleatória de notas fiscais de compras dos shoppers em todo o Brasil. As mais de 35 milhões de notas que processamos mensalmente garantem estatisticamente que reportemos preços e comportamentos de milhares de produtos com precisão. Porém, ainda é possível que alguns SKUs não cumpram critérios estatísticos mínimos em determinados meses, ficando de fora das observações. Nada que nos impeça de entregar dados e extrair insights extremamente ricos sobre o comportamento dos preços ao consumidor nas categorias de bens de consumo em massa.
              </div>
            </div>
          </>
        }

        {!fullPage ? null : (
          <>
            <Slider />
            <div className="hd-fgv__faq">
              <div className="hd-fgv__content">
                <h2 className="hd-fgv__title">Perguntas frequentes</h2>
                <div className="hd-fgv__faq-wrapper">
                  <FaqItem
                    title="O que é Cesta de Consumo Básica HORUS &amp; FGV IBRE ?"
                    ga="perg_cesta_consumo_basica"
                  >
                    A Cesta de Consumo Básica HORUS & FGV IBRE é composta por
                    produtos de consumo típico considerados mais relevantes
                    dentre as categorias de alimentos, tais como arroz, feijão,
                    açúcar, café, óleo, dentre outras. Dentro de cada categoria,
                    foi selecionado um segmento de produto com maior presença no
                    carrinho de compras do shopper para representá-la.
                    <br />
                    <br />
                    Por exemplo, foi selecionado o segmento Arroz Branco como
                    representante da categoria Arroz, em todas as cidades. Já
                    para a categoria Feijão, considerou-se o segmento Feijão
                    Carioca na maioria das capitais, exceto para Rio de Janeiro
                    e Curitiba, onde foi selecionado o Feijão Preto, por ser o
                    segmento de produto mais presente na cesta de compras.{" "}
                    <br />
                    <br />
                    Além disso, para cada categoria, foi estimada a quantidade
                    média adquirida de produtos (em quilos, litros ou unidades)
                    em uma compra típica de abastecimento, em cada capital.
                    <br />
                    <br />
                    Portanto, cada capital terá um perfil específico, de acordo
                    com a relevância e a quantidade média adquirida dos produtos
                    dessas categorias.
                    <br />
                    <br />
                    Para identificar as categorias que fazem parte de uma compra
                    típica de abastecimento, foram consideradas as compras com
                    valor superior a R$ 100,00 e com mais de 12 itens no cupom
                    fiscal.
                    <br />
                    <br />
                    <a
                      target="__blank"
                      href="/static/docs/fgv/Composição Cesta Básica.pdf"
                    >
                      Veja aqui a composição da Cesta de Consumo Básica
                    </a>
                  </FaqItem>
                  <FaqItem
                    title="O que é Cesta de Consumo Ampliada HORUS  &amp;  FGV IBRE ?"
                    ga="perg_cesta_consumo_ampliada"
                  >
                    Na Cesta de Consumo Ampliada HORUS & FGV IBRE, além de
                    alimentos, estão contemplados outros produtos de consumo
                    típico das categorias mais relevantes de bebidas, limpeza e
                    higiene e beleza. Além das categorias que compõem a Cesta de
                    Consumo Básica, outras categorias relevantes de alimentos
                    são também consideradas. No total, são 51 categorias de
                    produtos.
                    <br />
                    <br />
                    Para cada categoria, foi selecionado um segmento com maior
                    presença no carrinho de compras do shopper para
                    representá-la.
                    <br />
                    <br />
                    Por exemplo, foi selecionado o segmento Refrigerante tipo
                    Cola como representante da categoria Refrigerante, em todas
                    as cidades, por ser o mais presente na cesta de compras.
                    <br />
                    <br />
                    Além disso, para cada categoria, foi estimada a quantidade
                    média adquirida de produtos (em quilos, litros ou unidades)
                    em uma compra típica de abastecimento, em cada capital.
                    <br />
                    <br />
                    Portanto, cada capital terá um perfil específico, de acordo
                    com a relevância e a quantidade média adquirida dos produtos
                    dessas categorias. <br />
                    <br />
                    Como compra típica de abastecimento, foram consideradas
                    aquelas com valor superior a R$ 100,00 e com mais de 12
                    itens no cupom fiscal de compra.
                    <br />
                    <br />
                    <a
                      target="__blank"
                      href="/static/docs/fgv/Composição Cesta Ampliada.pdf"
                    >
                      Veja aqui a composição da Cesta de Consumo Ampliada
                    </a>
                  </FaqItem>
                  <FaqItem
                    title="Como é calculado o valor das cestas?"
                    ga="perg_calculo_valor_cesta"
                  >
                    Definidos os tipos de produtos (segmentos) que compõem cada
                    uma das cestas, em cada capital, considera-se a quantidade
                    média adquirida desses produtos nas compras típicas de
                    abastecimento da cidade. O valor total da cesta é calculado
                    multiplicando o preço médio de cada um dos segmentos pela
                    quantidade média adquirida, em cada capital.
                    <br />
                    <br />
                    <a
                      target="__blank"
                      href="/static/docs/fgv/Composição Cesta Ampliada.pdf"
                    >
                      Veja aqui a composição da Cesta de Consumo Ampliada{" "}
                    </a>{" "}
                    <br />
                    <a
                      target="__blank"
                      href="/static/docs/fgv/Composição Cesta Básica.pdf"
                    >
                      Veja aqui a composição da Cesta de Consumo Básica
                    </a>
                  </FaqItem>
                  <FaqItem
                    title="Como é formado o preço da categoria?"
                    ga="erg_preco_categoria"
                  >
                    Com uma base de mais de 35 milhões de notas fiscais
                    coletadas mensalmente, calcula-se o preço médio de compra de
                    todos os segmentos da categoria, a partir do preço médio
                    encontrado dos produtos que compõem estes segmentos, nas
                    redes de autosserviço e atacarejo da capital, onde houve
                    captura de informações por meio da nota fiscal. Os preços
                    estão convertidos para a unidade de medida do produto,
                    podendo ser quilo, litro ou unidade.
                    <br />
                    <br />
                    <b>
                      Para o cálculo de variação, com o intuito de garantir a
                      comparabilidade dos dados, considera-se apenas produtos
                      que constam nos 2 meses utilizados no cálculo.
                    </b>
                  </FaqItem>
                  <FaqItem
                    title="Quais categorias estão disponíveis na versão Plus? E na Premium?"
                    ga="perg_versao_plus_premium"
                  >
                    Todas as versões do produto Cesta HORUS & FGV IBRE abordam
                    as mesmas categorias. Nas versões Plus e Premium, é possível
                    visualizar os preços médios de cada uma delas em cada
                    capital. Na Premium é possível visualizar também os preços
                    dos diferentes segmentos, fabricantes, marcas e tamanhos de
                    embalagem. As categorias são:
                    <br />
                    <br />
                    Achocolatado/Modificador, Açúcar, Água Mineral, Água
                    Sanitária, Amaciantes para Roupa, Arroz, Azeite, Batata
                    Congelada, Biscoitos, Bovino, Café em Pó e em Grãos,
                    Cerveja, Chocolate, Creme de Leite, Creme Dental,
                    Desodorante, Detergente Líquido, Enlatados e Conservas,
                    Farinha de Mandioca, Farinha de Trigo, Fubá e Farinhas de
                    Milho, Feijão, Frango, Frutas (banana, laranja e maçã),
                    Hambúrguer, Iogurte, Legumes (batata Inglesa, cebola e
                    cenoura), Leite Condensado, Leite em Pó, Leite UHT,
                    Linguiça, Manteiga, Margarina, Massas Alimentícias, Massas
                    Instantâneas, Molho de Tomate, Óleo, Ovos, Pão, Papel
                    Higiênico, Queijos, Refrigerante, Requeijão, Sabão para
                    Roupa, Sabonete, Shampoo, Snacks e Salgadinhos, Suco Pronto,
                    Suíno, Verduras (alface, couve, repolho), Vinho.
                  </FaqItem>
                </div>
              </div>
            </div>
          </>
        )}
        <div className="hd-fgv__footer">
          <div className="hd-fgv__content">
            <div className="hd-fgv__footer__row">
              <div className="hd-fgv__footer__col">
                <img
                  className="hd-fgv__footer__horus-logo"
                  src="/static/logo-horus-w.png"
                  alt="Logo Horus"
                />
                <img
                  className="hd-fgv__footer__fgv-logo"
                  src="/static/fgv.png"
                  alt="Logo Horus"
                />
              </div>
              <div className="hd-fgv__footer__col">
                <h3>Menu</h3>
                <nav>
                  <ul>
                    <li>
                      <a href="https://www.ehorus.com.br/contato"
                        onClick={(e) => {
                          e.preventDefault();
                          GoogleAnalyticsActions.event(
                            "botao_contato",
                            "",
                            "ㅤ"
                          );
                          window.open(
                            "https://www.ehorus.com.br/contato",
                            "open"
                          );
                        }}
                      >
                        Contato
                      </a>
                    </li>
                    <li>
                      <Link to="/politica-de-privacidade">
                        Política de Privacidade
                      </Link>
                    </li>
                    <li>
                      <Link to="/termos-de-uso">Termos de Uso</Link>
                    </li>
                  </ul>
                </nav>
              </div>
              <div className="hd-fgv__footer__col">
                <h3>E-mail</h3>
                <nav>
                  <ul>
                    <li>
                      <a href="mailto:contato@ehorus.com.br">
                        contato@ehorus.com.br
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FgvPage;

import { FunctionComponent, useContext, useEffect } from 'react';
import EmbeddedDashboard from './EmbeddedDashboard';
import FgvContext from './FgvContext';
import FgvPage from './FgvPage';

const BASIC_DASHBOARD = "111E140_crs_brands_retail_metrics_professional";

const FgvPublicDashboard: FunctionComponent = function () {
  const { setDashboardLoaded } = useContext(FgvContext);

  useEffect(() => () => setDashboardLoaded(false), [ setDashboardLoaded ]);

  function onDashboardLoaded(e: { link: string }) {
    setDashboardLoaded(true);
  }

  return (
    <FgvPage fullPage>
      <EmbeddedDashboard 
        className="hd-fgb__body" 
        link={ BASIC_DASHBOARD }
        onLoadDashboard={ onDashboardLoaded }
      />
    </FgvPage>
  );
}

export default FgvPublicDashboard;
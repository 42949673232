import * as React from "react";
import { useHistory } from "react-router";
import { useHome } from "../../../../../service/home";
import { useModal } from "../../../../utils/Modal";
import CronogramaDashboard from "../../../modals/CronogramaDashboard";
import SeusDashboardsModal from "../../../modals/SeusDashboardsModal";
import TreinamentoHorusModal from "../../../modals/TreinamentoHorusModal";
import styles from "./Styles.module.css";
import { DashboardError } from "../../../modals/DashboardError";

const MenuBox: React.FC = () => {
  const { openModal } = useModal();
  const { home } = useHome();
  const history = useHistory();

  const viewGlossary = (url: string) => window.open(url, "_blank");

  return (
    <>
      <div className={styles.boxes}>
        <div className={styles.box}>
          <button
            onClick={() =>
              openModal(
                home ? (
                  <SeusDashboardsModal
                    history={history}
                    data={home?.dashboards}
                  />
                ) : (
                  <DashboardError />
                )
              )
            }
          >
            <img src="/static/meus_dashboards.png" alt="Meus dashboards" />
            <span>Meus dashboards</span>
          </button>
        </div>

        <div className={styles.box}>
          <button onClick={() => openModal(<TreinamentoHorusModal />)}>
            <img src="/static/treinamento.png" alt="Treinamento Horus" />
            <span>Treinamento Horus</span>
          </button>
        </div>

        <div className={styles.box}>
          <button
            onClick={() => viewGlossary("/static/docs/glossario_horus.pdf")}
          >
            <img src="/static/glossario.png" alt="Glossário" />
            <span>Glossário</span>
          </button>
        </div>

        <a
          className={styles.noTextDecoration}
          target="_blank"
          href="https://customer.ehorus.com.br/portaldocliente"
          rel="noreferrer"
        >
          <div className={styles.box}>
            <button>
              <img src="/static/fale_conosco.png" alt="Fale com a gente" />
              <span>Portal do Cliente</span>
            </button>
          </div>
        </a>

        <div className={styles.box}>
          <button onClick={() => openModal(<CronogramaDashboard />)}>
            <img src="/static/icone_calendario.png" alt="Glossário" />
            <span>Calendário</span>
          </button>
        </div>
      </div>
    </>
  );
};

export default MenuBox;
